$theme-colors: (
   success: #96c11e
);

.page-link {
  color: #96c11e !important;
}

.page-item.active .page-link {
  background-color: #96c11e !important;
  border-color: #96c11e !important;
  color:white !important;
}

.btn-success {
  color:white !important;
}

.btn-outline-success:hover {
  color:white !important;
}

.custom-file-label {
  overflow: hidden;
}

.card-img-res {
    max-height: 235px;
}

.card-body-home {
  background-color: #96c11e;
}

.backgrtools {
  background-color: #96c11e;
}

.text-attr {
  font-size: 2em;
}

.text-attr-mb {
  font-size: 1.5em;
}

.zipaddress {
  width:99% !important;
}

.zipaddressmb {
  width:98% !important;
}

.btnListJobMb {
  width:15%;
  height:3em;
}

.sub-title-attr {
  font-size: 0.9em;
}

.cardnoborder {
  border-style: none;
}

.resIdBox {
  border: 5px solid #96c11e;
}

.appyHere {
  font-size: 20px;
}

.appyHereContainer {
  position:relative;
}

.clickLogo {
  position:absolute;
  padding-left: 2%;
  padding-right: 2%;
  top:90%;
  left:9%;
  font-size: 16px;
  font-weight: bold;
  border: 3px solid #96c11e;
  background: white;
}

.line {
width: 100%;
border-bottom: 5px solid #96c11e;
//position: absolute;
}

.popup-input {
    text-align: center;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
  body {
    padding-bottom: 23rem;
  }
  .resIdBox {
    margin: 15px;
  }

  .clickLogo {
    padding-left: 1%;
    padding-right: 1%;
  }
}

// Medium devices (tablets, 768px and down)
@media (min-width: 768px) and (max-width: 1300px) {
  .text-attr {
    font-size: 1.2em;
  }
  .clickLogo {
    padding-left: 1%;
    padding-right: 1%;
    font-size: 14px;
  }
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_pagination.scss';
