html, body {
  max-width: 100%;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 20rem;
  min-height: 100%;
  font-family: "Montserrat", Sans-Serif;
  font-size: 0.8em;
}

.header {
  width: 100%;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.custom-file-input {
  cursor: pointer;
}

.custom-file-input ~ .custom-file-label::after {
    display: none !important;
    /*content: "" !important;*/
}

.radioStyle {
  border: 1px solid black;
}

.pagination-bottom {
  display: none;
}

.pointer {
  cursor:pointer;
}

.openPst {
  background-color: rgb(192,192,192, 0.5);
  visibility:hidden;
}

.closedPst {
  background-color: rgb(255, 0, 0, 0.5);
  visibility:hidden;
  font-size: 2.2em;
  color: white;
}

.txtAlertPst {
  font-size: 2.2em;
  color: white;
}

#readmore {
  padding: 0.15rem 0.5rem !important;;
}

.pdfStyle {
  color:red;
}

.pdfStyle:hover {
  color:red;
}

.emailpopupmessage {
  display:none;
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 6px;
  top: 3px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.textCenter {
  text-align: center;
}

@media (max-width: 576px) {
  .masthead {
    height: auto; /*test with no height*/
  }
}
