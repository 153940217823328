.bannierecontainer {
  width: 100%;
  position: relative;
  text-align: center;
}

.banniereimage {
  filter: brightness(58%);
}

.bannierecenteredtext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bannieretext {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #96C11E;
  font-size: 5.208vw;
}

.bannieresoustext {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 2.208vw;
}

.encadresousbanniere1 {
  padding:5%;
  background-color: #96C11E;
  color: #ffffff;
}

.encadresousbanniere2 {
  padding:5%;
  background-color: #E9F4C9;
}

.titreencadresousbanniere1 {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 1.458vw;
}

.paragrapheencadresousbanniere1 {
  font-family: "Montserrat", Sans-Serif;
  color: #ffffff;
  font-size: 1vw;
}

.titreencadresousbanniere2 {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #22263E;
  font-size: 1.458vw;
}

.paragrapheencadresousbanniere2 {
  font-family: "Montserrat", Sans-Serif;
  color: #5F727F;
  font-size: 1vw;
}

.paragraphe4encadresousbanniere2 {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #658213;
  font-size: 1vw;
}

.shortwhiteline {
  width: 20%;
  border-bottom: 5px solid #ffffff;
}

.shortgreenline {
  width: 20%;
  border-bottom: 5px solid #96C11E;
}

.shortgreenline10 {
  width: 20%;
  border-bottom: 2px solid #96C11E;
}

.encadresousbanniere3 {
  padding-left:15%;
  padding-right:15%;
}

.titreencadresousbanniere3 {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #3C3950;
  font-size: 2.208vw;
}

.paragraphe1textencadresousbanniere3 {
  font-family: "Montserrat", Sans-Serif;
  color: #5F727F;
  font-size: 1.458vw;
}

.encadrecoworker {
  padding:5%;
}

.coworkertitle1 {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #96C11E;
  font-size: 2.208vw;
}

.coworkertitle2 {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #3C3950;
  font-size: 2.208vw;
}

.coworkerbody {
  background-color: #F0F3F2;
}

.coworkertitle3 {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #3C3950;
  font-size: 1.458vw;
}

.coworkerparagraphe {
  font-family: "Montserrat", Sans-Serif;
  color: #3C3950;
  font-size: 1vw;
}

.encadrecogiroffer {
  background-color: #3C3C3C;
  padding:5%;
}

.emplhandstitle {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 2.208vw;
}

.empllisttext {
  font-family: "Montserrat", Sans-Serif;
  color: #ffffff;
  font-size: 1vw;
}

.encadrevotre {
  background-image: url("./Images/ATS-V2-chevrons.png");
}

.chevrontitle {
  font-family: "Montserrat", Sans-Serif;
  font-weight: bold;
  color: #3C3950;
  font-size: 1vw;
}

.chevrontextbox {
  height:60%;
  background-color: #ffffff;
  padding:5%;
  border-bottom: #96C11E 15px solid;
}

.chevrontext {
  font-family: "Montserrat", Sans-Serif;
  color: #5F727F;
  font-size: .8vw;
}

.whiteLine {
  width: 100%;
  border-bottom: 1px solid lightgrey;
}

.cogirrealestatetext {
  font-family: "Montserrat", Sans-Serif;
  font-weight: 900;
  color: #888888;
  font-size: 16px;
}

.emailtext {
  font-family: "Montserrat", Sans-Serif;
  color: #96C11E;
}

.footerGreytext {
  font-family: "Montserrat", Sans-Serif;
  color: #888888;
  font-size: 16px;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
  .titreencadresousbanniere1 {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 2.458vw;
  }

  .paragrapheencadresousbanniere1 {
    font-family: "Montserrat", Sans-Serif;
    color: #ffffff;
    font-size: 2vw;
  }

  .titreencadresousbanniere2 {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #22263E;
    font-size: 2.458vw;
  }
  
  .paragrapheencadresousbanniere2 {
    font-family: "Montserrat", Sans-Serif;
    color: #5F727F;
    font-size: 2vw;
  }
  
  .paragraphe4encadresousbanniere2 {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #658213;
    font-size: 2vw;
  }

  .shortwhiteline {
    width: 20%;
    border-bottom: 2px solid #ffffff;
  }
  
  .shortgreenline {
    width: 20%;
    border-bottom: 2px solid #96C11E;
  }

  .titreencadresousbanniere3 {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #3C3950;
    font-size: 3.208vw;
  }
  
  .paragraphe1textencadresousbanniere3 {
    font-family: "Montserrat", Sans-Serif;
    color: #5F727F;
    font-size: 2vw;
  }

  .coworkertitle1 {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #96C11E;
    font-size: 3.208vw;
  }
  
  .coworkertitle2 {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #3C3950;
    font-size: 3.208vw;
  }
  
  .coworkertitle3 {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #3C3950;
    font-size: 2.458vw;
  }
  
  .coworkerparagraphe {
    font-family: "Montserrat", Sans-Serif;
    color: #3C3950;
    font-size: 2vw;
  }

  .emplhandstitle {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 3.208vw;
  }
  
  .empllisttext {
    font-family: "Montserrat", Sans-Serif;
    color: #ffffff;
    font-size: 2vw;
  }

  .chevrontitle {
    font-family: "Montserrat", Sans-Serif;
    font-weight: bold;
    color: #3C3950;
    font-size: 2.458vw;
  }
  
  .chevrontextbox {
    height:60%;
    background-color: #ffffff;
    padding:5%;
    border-bottom: #96C11E 15px solid;
  }
  
  .chevrontext {
    font-family: "Montserrat", Sans-Serif;
    color: #5F727F;
    font-size: 2vw;
  }
  
}


